.text {
  height: var(--button-height);
  border: 1px solid var(--color--input-border-gray);
  border-radius: 0;
  font-family: var(--gt-america-mono);
  width: 100%;
  box-sizing: border-box;
  padding-left: 14px;
}

.textarea {
  min-height: 200px;
  height: auto;
  border: 1px solid var(--color--input-border-gray);
  border-radius: 0;
  font-family: var(--gt-america-mono);
  width: 100%;
  box-sizing: border-box;
  padding: 14px;
}

.charCount {
  display: flex;
  justify-content: flex-end;
}

.text:focus,
.textarea:focus {
  transition: all 0.05s ease 0s;
  box-shadow: none;
  outline: 2px solid var(--focus-color);
}

.largeTextArea {
  min-height: 400px;
}

.disabled {
  color: #9a9a9a;
}

.file {
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
}

.file>input {
  height: 0.1px;
  width: 0.1px;
}

.file>.fileText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 400;
}

.checkbox {
  width: auto;
  height: auto;
}

.tall {
  min-height: 45px !important;
  height: max-content !important;
  min-width: 200px;
}

.text::-ms-clear {
  width: 0;
  height: 0;
}

.medium {
  min-width: 280px;
  width: max-content;
}

@media (max-width: 500px) {
  .medium {
    min-width: 140px;
  }
}

.lg {
  min-width: 435px;
  width: max-content;
}

.lg {
  @media (max-width: 500px) {
    min-width: 280px;
  }
}

.error,
.error:focus {
  border: 2px solid var(--error-color);
}

.orange {
  border: 2px solid #f5a623;
}