.defaults {
  transition: all 0.1s ease 0s;
  display: inline-block;
  box-sizing: border-box;
}

.defaults > svg {
  fill: currentcolor;
  width: 100%;
  height: 100%;
}
.defaults > svg > path {
  fill: currentcolor;
}
.defaultSize {
  composes: defaults;
  fill: inherit;
  stroke: inherit;
  display: inline-flex;
  align-items: center;
  vertical-align: bottom;
}

.white > svg > path {
  fill: white;
}

.green > svg > path {
  fill: #005662;
}

.red > svg > path {
  fill: #d0021b;
}

.danger > svg > path {
  fill: #a94442;
}

.warning > svg > path {
  fill: #8a6d3b;
}

.info > svg > path {
  fill: #31708f;
}

.read > svg > path {
  fill: #945e88;
}

.unread > svg > path {
  fill: white;
}

.unread:hover > svg > path {
  fill: #232f3a;
}

.small > svg {
  width: 0.85em;
  height: 0.85em;
}

.sizex1 > svg {
  width: 1.1em;
  height: 1.1em;
}

.sizex2 > svg {
  width: 1.3em;
  height: 1.3em;
}

.sizex3 > svg {
  width: 1.6em;
  height: 1.6em;
}

.sizex4 > svg {
  width: 1.8em;
  height: 1.8em;
}

.sizex5 > svg {
  width: 2em;
  height: 2em;
  margin-top: -0.2em;
  margin-bottom: -0.3em;
  margin-left: 0.1em;
  margin-right: 0.6em;
}

.spaced {
  margin-left: 0.625em;
  margin-right: 0.625em;
}

.button {
  margin-top: 0.5em;
  cursor: pointer;
}

.tabButton {
  margin-top: 0.625em;
  opacity: 0.45;
}

.tabButton:hover {
  opacity: 1;
}

.noMargin > svg {
  margin: 0;
}

.black {
  color: black;
}
