.label {
  font-family: var(--fonts);
  position: relative;
  display: flex;
  padding-left: 1.8em;
  cursor: pointer;
  font-weight: 600;
  line-height: 1.2em;
  width: fit-content;
}

.label:hover>.fakeBox {
  background: lightgrey;
}


.inverted {
  padding: 8px;
}

.inverted>.fakeBox {
  margin-left: 12px;
  position: inherit;
}

.input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.fakeBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.2em;
  height: 1.2em;
  border: 1px solid #c6c6c6;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Checked state */
/* .label input:checked~.fakeBox {
  background-color: #ffffff75;
  border: 1px solid #c6c6c6;
} */