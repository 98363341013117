@import '../../scss/vars.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  &__inner {
    padding: 50px;
    background-color: $color--white;
    display: inline-block;
    min-width: 790px;
    max-width: 100%;
    position: relative;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);

    &--close {
      position: absolute;
      top: 50px;
      right: 50px;
      padding: 0;
      border: 0;
      background: none;
      cursor: pointer;
    }

    &--title {
      padding-right: 2rem;
      font-size: 1rem;
      line-height: 1.5rem;
      font-family: $font-gt-america-bold;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
  }
}
