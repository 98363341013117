.kalkulatorWrapper {
  background-color: var(--new-primary_01);
  display: flex;
  justify-content: space-evenly;
  padding: 3em 2em;
  align-items: center;
  bottom: 0;
  width: 100vw;
  margin-left: calc((100% - 100vw) / 2);
}

.kalkulatorContainer {
  width: 1260px;
}

.kalkulatorContainer h2 {
  font-size: 32px;
  margin-top: 0;
}

.kalkulatorItemsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.kalkulatorItemContainer {
  display: flex;
  flex-direction: column;
  margin-right: 2em;
}

.kalkulatorItemContainer:last-child {
  margin-right: 0;
}

.kalkulatorItemContainerSmall {
  width: 25%;
}

.kalkulatorItemContainerLarge {
  width: 50%;
}

.kalkulatorRow {
  display: flex;
  align-items: flex-end;
  margin-right: 2em;
}

.kalkulatorItemContainer label {
  font-weight: 600;
  margin: 1.5em 0 0.5em 0;
}
.kalkulatorItemContainer input {
  padding: 1em;
  height: 67px;
}
.kalkulatorItemContainer,
.kalkulatorItemContainer input {
  width: 100%;
}
.kalkulatorItemContainer input:disabled {
  background: none;
  opacity: 1;
  border: 1px solid gray;
  color: black;
}

.readOnlyText {
  font-weight: bold;
  font-size: 32px;
}

@media screen and (min-width: 1260px) {
  .kalkulatorContainer {
    width: 1260px;
    margin: 2em auto;
    padding: 0 2em;
  }
}

@media screen and (max-width: 768px) {
  .kalkulatorItemContainer {
    width: 100%;
    flex-direction: column;
    margin-bottom: 1em;
  }
  .kalkulatorRow {
    flex-direction: column;
    margin-right: 0;
    align-items: flex-start;
  }

  .kalkulatorItemsWrapper {
    flex-direction: column;
  }
}

@media print {
  .kalkulatorItemContainer input:disabled,
  .kalkulatorItemContainer input {
    background: transparent;
    border: none;
    color: black;
    font-size: 1rem;
    margin: 0 -8px;
    padding: 0 8px;
  }
}
