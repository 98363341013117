.DoublePanel {
  grid-area: generell;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 15px;
  align-items: start;
}

@media (max-width: 768px) {
  .DoublePanel {
    display: flex;
    flex-direction: column;
    grid-auto-flow: row;
    align-items: unset;
  }
}

.Label {
  grid-column: span 2;
}
