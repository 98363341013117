.default {
  font-family: var(--fonts);
  font-weight: normal;
  border-radius: 0;
  display: flex;
  align-items: center;
  animation-name: animScale;
  animation-duration: 0.35s;
  padding: 1em;
  border-radius: 4px;
}

.success {
  color: white;
  background-color: #147e87;
  border: 1px solid #005763;
}

.danger {
  color: white;
  background-color: #a94442;
}

.info {
  color: #31708f;
  background-color: #d9edf7;
  border: 1px solid #bce8f1;
}

.warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border: 1px solid #faebcc;
}

.children {
  margin-left: 0.3em;
}

@keyframes animScale {
  0% {
    opacity: 0;
    transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}
