.StickyContainer {
  position: relative;
}

.Header {
  min-height: 380px;
  background: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  position: relative;
}
.HeaderLogo {
  height: 120px;
  width: 600px;
  object-fit: contain;
  margin: auto auto;
}
.Article {
  position: relative;
  margin: var(--page-margin);
  padding: var(--page-padding);
  max-width: var(--content-max-width);
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-areas:
    'map map map'
    'Kontaktperson article article'
    'Kontaktperson article article '
    'files article article'
    'location area transport';
  gap: 30px;
}

.ArticleBody {
  grid-area: article;
  color: #232e3a;
}

.ArticleBody h1 {
  margin-top: 0;
  font-size: 48px;
  font-weight: bold;
}

.UnPublishedMessage {
  background: var(--tertiary_03);
  color: black;
  font-size: 18px;
  font-weight: bold;
  font-family: var(--gt-america-mono);
}

.Article h3 {
  margin: 0;
  grid-column: 1/-1;
}

.location {
  grid-area: location;
}

.transport {
  grid-area: transport;
}

.area {
  grid-area: area;
}

.sokeomrade {
  grid-area: map;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

.Files {
  grid-area: files;
}

.sokeomrade a {
  flex: 1 1 0;
}
.sokeomrade a img {
  width: 100%;
}

.svar {
  margin: var(--page-margin);
  margin-bottom: 0;
  padding: var(--page-padding);
  max-width: var(--content-max-width);
  text-align: right;
  font-size: 1.5em;

  position: sticky;
  bottom: 0;
  padding-bottom: 90px;
}

.svar a {
  background: white;
  margin-left: 10px;
}

.svar button {
  margin: 0;
  margin-left: auto;
}

.HeadingContainer {
  padding: 0 40% 5% 0;
}

.panel {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  min-height: 250px;
}
.panel h3 {
  margin-bottom: 36px;
}

.PanelEntry {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}
.PanelEntry p {
  margin: 0;
}

.PanelEntry svg {
  margin-right: 36px;
  height: 30px;
  width: 30px;
}

.FylkeKommune {
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
}

.FylkeKommune .PanelEntry {
  margin: 5px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .Article {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas:
      'map map'
      'files files'
      'article article'
      'Kontaktperson location'
      ' area transport';
  }
}
@media screen and (max-width: 767px) {
  .Article {
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas:
      'map'
      'article'
      'Kontaktperson'
      'files'
      'location'
      'area'
      'transport';
  }
  .HeadingContainer {
    padding: 0;
  }
  .panel {
    border-bottom: none;
  }

  /* .panel:last-child {
    border-bottom: 1px solid black;
  } */
}
