.befaringInnkomneKart{
   
}

.map {
    height: 700px;
    display: grid;
    padding-bottom: 2em;
  }
  
