@import '../../scss/vars.scss';

.accordion {
  &.-with-borders {
    border-top: 1px solid $color--input-border-gray;
    border-bottom: 1px solid $color--input-border-gray;
  }

  &__trigger {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2.5rem;
    line-height: 3.4375rem;
    font-family: $font-gt-america-bold;
    background: none;
    border: 0;
    padding: 30px 0 30px 0;
    text-align: left;
    cursor: pointer;
  }

  &__content {
    padding-bottom: 30px;
  }
}
