div.Kontaktperson {
  grid-area: Kontaktperson;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-areas:
    'heading heading'
    'picture info'
    'children children';
  grid-gap: 10px;
  margin-bottom: 0;
}

.Kontaktperson p,
.Kontaktperson a {
  margin: 0;
}
.Kontaktperson h3 {
  grid-area: heading;
  margin-top: 0;
  grid-column: 1/-1;
}

.Kontaktperson img {
  grid-area: picture;
  object-fit: contain;
  object-position: top;
  width: 100px;
  height: 100px;
}

.Kontaktinfo {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Name {
  grid-area: name;
  color: #232f3a;
  font-size: 16px;
  font-weight: bold;
}

.Info {
  grid-area: info;
  font-size: 14px;
}
.Title {
  grid-area: title;
  font-size: 14px;
}

.Phone {
  grid-area: phone;
  font-size: 14px;
}

.Email {
  grid-area: email;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Children {
  grid-area: children;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.LinkColor {
  color: var(--linkColor);
}
