:root {
  --buttonWidth: 25px;
  --buttonHeight: calc(var(--buttonWidth) * 0.75);
}

.menuNavigationItem {
  display: table;
  color: black;
  text-decoration: none;
  margin-bottom: 15px;
}
.menuNavLast {
  margin-bottom: 30px;
}

.menuNavigationItemSmall {
  display: table;
  color: black;
  text-decoration: none;
  font-family: var();
  margin-bottom: 6px;
}

.menuNavSelected {
  font-weight: bold;
}

.menuButton {
  background: transparent;
  outline: none;
  border: none;
  width: var(--buttonWidth);
  height: var(--buttonHeight);
  position: relative;
  /* margin: 50px auto; */
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 50;
}

.menuButton span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: black;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.menuButton span:nth-child(1) {
  top: 0px;
}

.menuButton.open span:nth-child(1) {
  top: calc(var(--buttonHeight) * 0.2);
  width: 0%;
  left: 50%;
}

.menuButton span:nth-child(2),
.menuButton span:nth-child(3) {
  top: calc(var(--buttonHeight) * 0.5);
}

.menuButton span:nth-child(4) {
  top: calc(var(--buttonHeight) * 1);
}

.menuButton.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menuButton.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menuButton.open span:nth-child(4) {
  top: calc(var(--buttonHeight) * 0.2);
  width: 0%;
  left: 50%;
}

.slideInMenu {
  height: 100vh;
  padding: 100px 0;
  opacity: 0;
  width: 0;
  transition: opacity 200ms ease-in-out, width 400ms ease-in-out;
  z-index: 40;
  background-color: white !important;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}

.slideInMenuOpen {
  display: block;
  opacity: 1;
  width: 300px;
}

.slideInMenu * {
  white-space: nowrap;
}

@media (min-width: 1268px) {
  .slideInMenuOpen {
    width: 42vw;
  }
}
@media (max-width: 768px) {
  .slideInMenu {
    transition: opacity 200ms ease-in;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.removeDiv {
  display: none;
}
