.IconButton {
  --color: currentColor;
  background: none;
  border: 2px solid var(--color);
  padding: 10px;
  box-sizing: border-box;
  color: var(--color);
  font-size: inherit;
  line-height: 0;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.2s;
  height: var(--button-height);
  width: var(--button-height);
}

.IconButton svg {
  transition: color 0.2s;
  height: 100%;
  width: 100%;
}

.IconButton.normal {
  --color: #005763;
}

.IconButton.noBorder {
  --color: #005763;
  border: none;
}

.IconButton.warning {
  --color: darkgoldenrod;
}

.IconButton.danger {
  --color: darkred;
}

.IconButton.rounded {
  --color: #005763;
  background: #005763;
  color: white;
  border-radius: 20px;
  padding: 5px 20px;
}

.IconButton:hover,
.IconButton:focus {
  background: var(--color);
}

.IconButton:hover svg,
.IconButton:focus svg {
  color: white;
}

.IconButton.white {
  --color: white;
}
.IconButton.white:hover,
.IconButton.white:focus {
  --color: #005763;
}

/* .IconButton.large svg {
  width: calc(var(--button-height) * 1.1);
  height: calc(var(--button-height) * 1.1);
  transition: color 0.2s;
  padding: 5px;
}

.IconButton.small svg {
  width: calc(var(--button-height) / 1.1);
  height: calc(var(--button-height) / 1.1);
  transition: color 0.2s;
  padding: 1px;
} */
