.SvarInnkomneSide {
  display: grid;
  gap: 20px;
}

.SvarInnkomneSide h1 {
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.visningBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.printDate {
  display: none;
}

@media print {
  .titleIcons {
    display: none;
  }

  .toolbar {
    display: none;
  }

  div.printDate {
    display: block;
  }
}

.toolbar button {
  border: transparent;
  background: none;
  padding: 0;
  margin: 10px;
  cursor: pointer;
  font-size: 16px;
  font-family: inherit;
  display: inline-flex;
  align-items: center;
  transition: border-color 0.5s;
  outline: none;
}

.toolbar button svg {
  margin-right: 5px;
}

.toolbar button.active {
  border-bottom: 2px solid #88c9d0;
}

.empty {
  display: block;
}

.map {
  height: 700px;
  display: grid;
  padding-bottom: 2em;
}

.popup {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.popup a.link {
  justify-self: stretch;
  align-self: center;
  text-decoration: none;
  color: white;
  padding: 10px;
  background: #005763;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score {
  display: grid;
  grid-template-columns: 1fr 20px;
  gap: 5px;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: white;
}

.dotBad {
  background: #cf4f13;
}

.dotMiddle {
  background: #f9b100;
}

.dotGood {
  background: #5c9424;
}

.ikkeoppfort > div:first-child {
  border-left: 5px solid #1d1d1d;
}

.totalRehab > div:first-child {
  border-left: 5px solid #9a7512;
}

.eksisterende > div:first-child {
  border-left: 5px solid #9c296d;
}
