.Toolbar {
  display: flex;
  flex-direction: column;
}

.Buttons {
  display: flex;
  column-gap: 24px;
  align-items: center;
  margin-bottom: 1em;
  justify-content: start;
}
.Buttons svg {
  font-size: 2.8em;
}

.Buttons button {
  margin: 0;
}
