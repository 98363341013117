.feedbackMargin {
  margin-bottom: 34px;
}

.feedback {
  padding-top: 2px;
}

.tall {
  min-width: 200px;
}

.relative {
  position: relative;
}

.vertical {
  display: flex;
  flex-direction: column;
  column-gap: 15px;
  row-gap: 5px;
}

.horizontal {
  display: flex;
  align-items: center;
  padding: 10px;
}

.horizontal > :first-child {
  min-width: 200px;
}

.horizontal > .feedback {
  margin: 50px 0 10px 200px;
}

.required {
  color: #b1b1b1;
  margin-left: 3px;
  font-weight: 300;
}

.readOnly {
  margin-top: 15px;
}

.time{
  height: var(--button-height);
  border: 1px solid var(--color--input-border-gray);
  border-radius: 0;
  font-family: var(--gt-america-mono);
  width: 100%;
  box-sizing: border-box;
}

.time > input{
  height: 100%;
  border: 0px;
  padding: 0px 15px;
}
