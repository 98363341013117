.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  padding-bottom: 1em;
}

.topBar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.topBar > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomBar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-top: 2rem;
}

.saveMapWrapper {
  margin-left: auto;
}

.saveStatus {
  margin-right: 1em;
}

.wrapper button.expandButton {
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  z-index: 1000;
  border: none;
  display: block;
}

.expandIcon {
  background: none;
  border: none;
}

.serviceAreaDistanceInputWrapper {
  display: flex;
  justify-content: center;
}
.serviceAreaDistanceField {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  position: relative;
  font-size: 14px;
}
.serviceAreaDistanceField input {
  width: 80px;
  margin-left: 10px;
}

.searchToolbar {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 14px;
}

.searchToolbar input {
  padding: 5px 8px;
  min-width: 400px;
  margin-left: 10px;
}

.searchResultList {
  list-style: none;
  padding-left: 0;
  position: absolute;
  left: 0;
  top: 16px;
  border: 1px solid black;
  width: 100%;
  background: white;
  z-index: 10;
}

.searchResult {
  padding: 5px;
  text-align: left;
  transition: background-color 300ms;
  box-shadow: 0 1px 0 black;
}

.searchResult:hover {
  background-color: #e6e6e6;
}

.searchResult button {
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;
}

.arcGisToolbar {
  margin: 20px 0;
}

.arcGisToolbar button {
  margin: 0 0.5em !important;
  padding: 0.5em !important;
}

.arcGisToolbar button svg {
  width: 1.6em !important;
  height: 1.6em !important;
}
