.headerWrapper {
  background-color: var(--new-primary_01);
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  align-items: center;
  bottom: 0;
  width: 100vw;
  margin-left: calc((100% - 100vw) / 2);
}

.headerContainer {
  width: 1260px;
}

@media screen and (min-width: 1260px) {
  .headerContainer {
    width: 1260px;
    margin: 2em auto;
    padding: 0 2em;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inputSection {
  width: 100%;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 411px) {
  .inputContainer {
    flex-direction: column;
  }

  .inputSection {
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .inputContainer {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .inputSection {
    width: 45%;
    flex-direction: row;
  }
}

.dateInputWrapper {
  width: 100%;
}
.dateInputWrapper > div {
  display: flex !important;
  column-gap: 0;
  width: 100%;
  flex-direction: column;
}

@media screen and (min-width: 411px) {
  .dateInputWrapper > div {
    flex-direction: row;
  }
}

.dateInputWrapper > div > div {
  width: 100%;
}

.inputSection .headerLabel,
.dateInputWrapper label {
  display: inline-block;
  min-width: 170px;
  padding: 13px 0 7px 0;
  font-weight: normal;
}

.inputSection .headerInput,
.dateInputWrapper input {
  width: 100%;
  height: 42px;
  border: 1px solid black;
  flex: 1;
  padding: 16px 8px;
  font-family: 'Open Sans', sans-serif;
}

.inputSection .headerInput:disabled {
  background: transparent;
  border: none;
  color: black;
  font-size: 1rem;
  margin: 0 -8px;
}

@media print {
  .inputSection .headerInput,
  .dateInputWrapper input {
    background: transparent;
    border: none;
    color: black;
    font-size: 1rem;
    margin: 0 -8px;
    padding: 0 8px;
  }
}
