.LabeledField {
  display: grid;
  column-gap: 15px;
  row-gap: 5px;
}

.LabeledField > label:first-child {
  grid-column: 1/-1;
}

.LabeledField > div.ErrorMessage {
  grid-column: 1/-1;
}

.label {
  display: block;
  color: var(--primary-font-color);
  padding-right: 10px;
}
