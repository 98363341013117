.paragraph {
  color: var(--defaultColor);
  font-family: var(--fonts);
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.375rem;
  margin: 0.2rem 0 0;
  white-space: pre-wrap;
}

.ingress {
  font-size: 1.2rem;
  line-height: 1.6875rem;
  font-weight: normal;
}

.bold {
  font-weight: 400;
}

.inline {
  display: inline;
}

.spaced {
  margin-right: 20px;
}

.zeromargin {
  margin: 0px;
}
