.ScoreUnit {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  cursor: auto;
}

.TriPieWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

.Rating {
  grid-area: rating;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  grid-template-rows: auto;
  grid-gap: 30px;
  justify-content: space-between;
  align-items: stretch;
}

.Rating > div {
  box-shadow: 0 10px 20px 0 #48656f0d;
  background: white;
  padding: 20px;
  /* text-align: center; */
  cursor: pointer;
  position: relative;
}

.Rating .triPieSvg {
  margin: 10px;
}
.justertText {
  color: var(--linkColor);
  text-align: left;
}

.RatingMultiRows {
  grid-area: rating;
  display: flex;
  flex-wrap: wrap;
  margin: 2px;
}

.RatingMultiRows > div {
  margin: 8px;
  flex: 1 1 calc(25% - 1rem);
  box-shadow: 0 10px 20px 0 #48656f0d;
  background: white;
  padding: 20px;
  /* text-align: center; */
  cursor: pointer;
  position: relative;
}

.RatingMultiRows .triPieSvg {
  width: 200px;
  margin: 10px;
  margin: 0 auto;
}

@media print {
  .Rating {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media screen and (min-width: 411px) {
  .Rating {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media screen and (min-width: 768px) {
  .Rating {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

.expandIcon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  z-index: 1000;
}

@media print {
  .expandIcon {
    display: none;
  }
}
