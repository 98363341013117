.ImageUploadGrid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(3, 330px);
  grid-gap: 20px;
}

.ImageUploadGrid.noRows {
  grid-template-rows: repeat(1, 0);
}

.ImageUploadGrid.oneRow {
  grid-template-rows: repeat(1, 330px);
}

.ImageUploadGrid.twoRows {
  grid-template-rows: repeat(2, 330px);
}

.imageItem {
  position: relative;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: dotted 2px gray;
}
.imageItemReadOnly {
  border: none;
}

@media (max-width: 768px) {
  .ImageUploadGrid {
    display: flex;
    flex-direction: column;
  }
  .ImageUploadGrid .imageItem {
    height: 250px;
  }
}

.imageItem svg {
  font-size: 30px;
}
.imageItem p {
  margin: 0;
}

.imageItem .imageFileUploadInput {
  background: pink;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.imageItem .image {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
}

.imageItem:nth-child(1) {
  grid-row: 1;
  grid-column: 1/13;
}
.imageItem:nth-child(2) {
  grid-row: 2;
  grid-column: 1/7;
}
.imageItem:nth-child(3) {
  grid-row: 2;
  grid-column: 7/13;
}
.imageItem:nth-child(4) {
  grid-row: 3;
  grid-column: 1/5;
}
.imageItem:nth-child(5) {
  grid-row: 3;
  grid-column: 5/9;
}
.imageItem:nth-child(6) {
  grid-row: 3;
  grid-column: 9/13;
}

.imageButton {
  background: white;
  position: absolute;
  right: 0;
  padding: 8px;
  z-index: 1000;
  border: none;
  display: block;
  cursor: pointer;
}

.imageButton svg {
  background: none;
  border: none;
  font-size: 16px;
}
.expandButton{
  top:0;
}
.deleteButton{
  bottom: 0;
}

@media print {
  .imageItem .imageFileUploadInput,
  .emptyAddComment,
  .editButton,
  .uploadIconWrapper,
  .imageBoxWrapperHiddenPrint,
  .expandButton {
    display: none;
  }
  .imageItem {
    border: none;
  }

  .imageItemEmpty {
    display: none;
  }
}
