.wrapper {
  display: flex;
  flex-direction: column;
}
.titleWrapper{
  display: flex;
  align-items: flex-start;
  gap: 3em;
  justify-content: space-between;
}
.title {
  font-size: 62px;
  width: 50%;
}

.recindLabel{
    padding: 10px 15px 10px 15px;
    background-color: #ffa7a7;
    border-radius: 30px;
    font-weight: 700;
    font-size: 16px;
    
  }

.margin {
  margin-bottom: 2em;
}

.recommendedBox {
  background: #5c9424;
  color: white;
  font-weight: bold;
  font-size: 64px;
  text-align: center;
  padding: 1em 0;
  width: 100vw;
  margin-left: calc((100% - 100vw) / 2);
}

.befaringH2 {
  text-align: center;
  font-size: 32px;
  margin: 1em 0 2em 0;
  font-weight: normal;
  font-style: italic;
  font-family: var(--fonts);
}

.forbeholdBox {
  background-color: var(--primary-color);
  color: white;
  padding: 3em 2em;
  width: 100vw;
  margin-left: calc((100% - 100vw) / 2);
}

.forbeholdBox h2 {
  font-size: 32px;
  margin-top: 0;
}

.forbeholdContent {
  width: 100%;
}
@media screen and (min-width: 1260px) {
  .forbeholdContent {
    width: 1260px;

    margin: 2em auto;
    padding: 0 2em;
  }
}

.actionsBar {
  padding: 2em 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.actionsBar button {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .actionsBar {
    padding: 2em 3em;
  }
  .actionsBar button {
    width: unset;
  }
}

@media print {
  .recommendedBox {
    display: block;
  }
  .actionsBar {
    display: none;
  }
}
