.svar {
  box-shadow: 0 10px 20px 0 #48656f0d;
  background-color: #fff;
  display: grid;
  grid-template-columns: 200px auto;
  grid-template-rows: max-content;
  grid-template-areas: 'image info';
  color: inherit;
  text-decoration: none;
  position: relative;
  page-break-inside: avoid;
}

@media (max-width: 1024px) {
  .svar {
    grid-template-columns: 150px auto;
  }
}
@media (max-width: 768px) {
  .svar {
    grid-template-columns: 130px auto;
  }
}

.tilbefaringLabel {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.tilbefaringLabel input {
  margin-right: 16px;
}

.tilbefaringLabel:hover > input {
  cursor: pointer;
  box-shadow: 0 0 3px rgb(138, 138, 138);
}
.svarPicture {
  grid-area: image;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.svarPicture img {
  object-fit: cover;
  flex: 1;
}

.svarStand {
  background-color: black;
  text-transform: capitalize;
  color: white;
  text-align: center;
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.svarStand p {
  margin: 0;
}

.ingenStand {
  display: none;
}

.ikkeoppfort {
  background-color: #1d1d1d;
}

.totalRehab {
  background-color: #9a7512;
}

.eksisterende {
  background-color: #9c296d;
}

.totalScore {
  position: absolute;
  right: 0;
  height: 100%;
  width: 10px;
  bottom: 0;
}

@media (max-width: 1024px) {
  .totalScore {
    width: 10px;
  }
}

.totalScore {
  background-color: red;
}

.totalScoreBad {
  background-color: #cf4f13;
}

.totalScoreMiddle {
  background-color: #f9b100;
}

.totalScoreGood {
  background-color: #5c9424;
}

.totalScoreNone {
  background-color: transparent;
}

.info {
  flex: 1 0 0;
  margin-left: 1em;
  display: grid;
  padding: 1em;
  grid-template-areas:
    'heading heading'
    'details rating';
  grid-template-columns: 1fr 2fr;
  grid-template-rows: min-content auto;
  gap: 10px;
  margin-right: 1em;
}

@media (max-width: 1024px) {
  .info {
    grid-template-areas:
      'heading'
      'details'
      'rating';
    grid-template-rows: min-content auto auto;
    grid-template-columns: auto;
  }
}

@media (max-width: 768px) {
  .info {
    margin: 0;
    grid-template-rows: min-content auto;
    grid-template-columns: auto;
    grid-template-areas:
      'heading'
      'details';
  }
}
.heading {
  grid-area: heading;
  display: flex;
  justify-content: space-between;
}

.heading h3 {
  margin: 0;
}

.details {
  grid-area: details;
  line-height: 2em;
}

.blueColor {
  color: var(--primary_02);
  font-family: var(--gt-america-bold);
}

.rating {
  grid-area: rating;
  gap: 10px;
  justify-content: space-between;
  align-content: center;
  text-align: center;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
}

.Scoreitem {
  width: 100px;
}

.rating svg {
  width: 100%;
}
/* .rating > span {
  min-width: 100px;
} */

@media (max-width: 1024px) {
  div.details > div {
    display: none;
  }
  div.details > div.showOnAllDevices {
    display: block;
  }
}

@media (max-width: 768px) {
  .rating {
    display: none;
  }

  div.details > div.showOnAllDevices {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  div.details > div.showOnAllDevices > span.hideMobile {
    display: none;
  }
}

@media print {
  .svar {
    grid-template-columns: 130px auto;
    grid-template-rows: max-content;
    grid-template-areas: 'image info';
  }

  .info {
    grid-template-areas:
      'heading heading'
      'details rating';
    grid-template-columns: 1fr 2fr;
    grid-template-rows: min-content auto;
  }

  div.details > div {
    display: block;
  }
  div.details > div.showOnAllDevices {
    display: block;
  }
  .rating {
    display: grid;
    padding-right: 20px;
  }
  .totalScore {
    width: 40px;
  }
}

.rating svg {
  width: 100%;
}

.DragAndDropItem {
  width: 100%;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0.5em 1em;
  /* margin: 1.5em 0; */
}

.DragAndDropItem .totalScore {
  width: unset;
}
