@import '../../scss/vars.scss';
@import '../../scss/mixins.scss';

.advanced-file-selector {
  @include breakpoint-below-tablet {
    flex-direction: column;
    padding: 0 5%;

    span {
      margin-bottom: 1em;
    }
  }

  min-height: 114px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23BFBFBFFF' stroke-width='4' stroke-dasharray='15' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  padding: 0 38px;
  margin-bottom: 2rem;

  &.-invalid {
    border: 3px solid red;
    border-radius: 2px;
  }

  &.-disabled {
    opacity: 0.5;
  }

  &.-auto-upload {
    background-color: #5858581f;
    border-radius: 2px;
  }

  &__icon {
    @include breakpoint-below-tablet {
      margin-top: 20px;
    }

    margin-right: 10px;

    span {
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #bfe0e4;
      border-radius: 50%;
    }
  }

  &__text {
    @include breakpoint-below-tablet {
      padding: 1em 0em;
    }

    font-family: $font-gt-america;

    span {
      display: block;

      &:first-child {
        font-size: 1.125rem;
        line-height: 1.6875rem;
        color: $color--betong;
      }

      &:nth-child(2) {
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #585858;
      }

      strong {
        color: $color--glassbla03;
        text-decoration: underline;
        font-weight: 400;
      }
    }
  }

  input {
    display: none;
  }
}
