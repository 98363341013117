@import '../../scss/vars.scss';

.RadioBox {
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: $font-gt-america;
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;

    &:checked + div {
      div {
        opacity: 1;
      }
    }
  }

  &__check {
    margin-right: 14px;
    width: 20px;
    height: 20px;
    border: 2px solid #c4c4c4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--inner {
      background-color: $color--glassbla01;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      opacity: 0;
      transition: 150ms ease;
    }
  }
}
