.SvarRedigerContainer {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-areas:
    'title        title         Kontaktperson'
    'kopier       kopier        kopier'
    'kontaktinfo  kontaktinfo   kontaktinfo'
    'matrikkel    matrikkel     matrikkel'
    'generell     generell      generell'
    'kollektiv    kollektiv     transport'
    'bygg         energi        energi'
    'areal        pris          pris'
    'bilde        kommentar     kommentar'
    'errorList    errorList     errorList'
    'buttons      buttons       buttons';
  gap: 30px;
  align-items: start;
}

.SvarRedigerContainer > div {
  margin-bottom: 0;
  height: 100%;
}

div.titles {
  grid-area: title;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titles h1 {
  margin: 0;
  color: #232e3a;
}

.titles h2 {
  margin: 0;
  color: #147e88;
}

.kopier {
  grid-area: kopier;
}

div.popupButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
}

div.popupButtons button:first-child {
  margin-right: 10px;
}

.kopier > div {
  display: flex;
}

.kopier > div > div {
  flex: 1 1 auto;
}

.kopier > div > button[data-busy] {
  cursor: wait;
}

.kontaktinfo {
  grid-area: kontaktinfo;
}

div.matrikkel {
  grid-area: matrikkel;
  margin-bottom: 0;
  grid-gap: 30px;
  display: flex;
  flex-direction: column;
}

div.matrikkel > div {
  margin-bottom: 0;
}

div.matrikkelpanel {
  position: relative;
}

div.matrikkelpanel > button {
  position: absolute;
  top: 10px;
  right: 10px;
}

div.addMatrikkel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.generell {
  grid-area: generell;
  margin-bottom: 0;
}

div.transport {
  grid-area: transport;
  margin-bottom: 0;
}

div.kollektivTransport {
  grid-area: kollektiv;
  margin-bottom: 0;
}

div.bygg {
  grid-area: bygg;
  margin-bottom: 0;
}

div.energi {
  grid-area: energi;
  margin-bottom: 0;
}

div.areal {
  grid-area: areal;
  margin-bottom: 0;
}

div.pris {
  grid-area: pris;
  margin-bottom: 0;
}

div.bilde {
  grid-area: bilde;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: min-content minmax(0, 1fr);
  align-items: center;
  column-gap: 15px;
}
div.bilde h3,
div.bilde h4 {
  grid-column: 1 / -1;
}

div.kommentar {
  grid-area: kommentar;
  margin-bottom: 0;
}

ul.errorList {
  grid-area: errorList;
  list-style: none;
  padding: 0;
}

ul.errorList li {
  position: relative;
  display: flex;
  flex-direction: column;
}

ul.errorList aside {
  margin-top: 50px;
  color: #636363;
}

ul.errorList button {
  background: none;
  border: none;
  text-decoration: underline;
  color: #0000ee;
  font-size: 16px;
  margin-left: 10px;
}

div.toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

div.buttons {
  grid-area: buttons;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 140px;
}

.buttons button {
  margin-right: 24px;
}

.fullSpan {
  grid-column: 1 / -1;
}

.buttonRow {
  display: flex;
}
