.help {
  --top: 136px;
  --bottom: 30px;
  position: fixed;
  z-index: 10;
  right: 0;
  top: var(--top);
  max-height: calc(100vh - var(--top) - var(--bottom));
  overflow: auto;
  word-wrap: break-word;
  width: 400px;
  background: white;
  padding: 10px;
  box-shadow: 0 0 10px #00000088;
  transform: translateX(calc(100% + 10px));
  transition: transform 0.5s;
}

.help.active {
  transform: translateX(0);
}

.close {
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  background: none;
  padding: 10px;
  color: #aaa;
  cursor: pointer;
}

button.icon {
  display: inline-flex;
  align-items: center;
  border: none;
  background: none;
  font-size: inherit;
  padding: 0;
  cursor: pointer;
}

button.icon svg {
  margin-right: 10px;
}

.noText {
  padding: 4em;
  color: #aaa;
  text-align: center;
}
