.button {
  height: var(--button-height);
  padding: 0.375em 0.75em;
  /* margin-bottom: 0.3em; */
  font-family: var(--gt-america-bold);
  font-size: 18px;
  letter-spacing: 0.05em;
  line-height: 22px;
  transition: all 0.3s ease 0s;
  background-image: none;
  box-shadow: none;
  border-radius: 0;
  background-color: #147e87;
  width: max-content;
  white-space: nowrap;
  cursor: pointer;
}

.icon {
  font-weight: 300;
  padding: 0;
  height: var(--button-height);
  width: var(--button-height);
  font-family: var(--fonts);
  font-size: 18px;
}

.icon svg {
  height: 100%;
}

.button:hover svg {
  /* turn icons white when hover */
  stroke: var(--secondary-button-hover-color);
}

.primary {
  text-transform: uppercase;
  background-color: var(--button);
  color: var(--button-color);
  border: 2px solid var(--button);
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary.disabled {
  background-color: var(--button-disabled);
  border: 2px solid var(--button-disabled);
}

.primary:active,
.primary:hover {
  background-color: var(--button-hover);
  color: var(--button-color);
}

.primary:focus {
  outline: 2px solid var(--button-focus);
}

.secondary {
  font-size: 16px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  border: 2px solid var(--button);
  background-color: #fff;
  color: black;
}

.secondary.disabled {
  border: 2px solid var(--button-disabled);
  color: var(--button-disabled);
}

.secondary:focus {
  outline: 2px solid var(--button-focus);
}

.secondary:active,
.secondary:hover {
  background: var(--primary_01);
}

.textButton {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  border: none;
  box-shadow: 0 1px 0 var(--linkColor);
  background: none !important;
  font-family: var(--gt-america-mono);
  color: var(--linkColor);
  padding: 0.375em 0 0 0;
  margin-bottom: 0.375em;
}

.textButton:focus,
.textButton:active,
.textButton:hover {
  color: var(--linkHover);
  border: none;
  box-shadow: 0 2px 0 var(--linkColor);
}

.textButton:disabled {
  color: var(--button-disabled-darker);
  box-shadow: 0 1px 0 var(--button-disabled-darker) !important;
}

.disabled,
.disabled:hover {
  cursor: not-allowed;
}

.borderless {
  border: none;
}

.minWidth {
  min-width: 10em;
  width: max-content;
}

.active {
  border: 3px solid var(--button-hover);
}

.active:hover {
  background-color: white;
  color: var(--button-hover);
}

.LinkAsButton {
  height: var(--button-height);
  padding: 0.375em 0.75em;
  font-family: var(--gt-america-bold);
  font-size: 18px;
  letter-spacing: 0.05em;
  line-height: 22px;
  transition: all 0.3s ease 0s;
  background-image: none;
  box-shadow: none;
  border-radius: 0;
  width: max-content;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  background-color: var(--button);
  color: var(--button-color);
  border: 2px solid var(--button);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  cursor: wait;
  filter: opacity(0.4);
}
