.error {
  font-family: var(--fonts);
}

.feedback {
  margin-left: 0.4em;
}

.absolute {
  position: absolute;
}
