.befaringProsjektKriterier{
  display: grid;
  gap: 40px;
  margin-bottom: 5em;
}

.InputContainer {
  display: flex;
  width: 100%;
  margin-bottom: 2em;
}

.InputContainer label {
  width: 40%;
  display: flex;
  flex-direction: column;
  font-size: 26px;
  line-height: 30px;
  font-weight: bold;
}

.Input {
  width: 100%;
  font-family: var(--fonts);
  font-size: 18px;
  line-height: 27px;
  padding: 0.5em 1em;
  border: 1px solid var(--packet-color-font);
}

.AdditionalLabel {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--packet-color-font);
  margin-top: 0.5em;
}

.actionsBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
