.ForsideTomtesokSide {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Grid {
  margin: 30px 0;
  display: grid;
  /* grid-template-columns: 1fr; */
  column-gap: 30px;
  row-gap: 60px;
  grid: auto / repeat(6, 1fr);
}

.GridItem {
  grid-column: span 6;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .GridItem {
    grid-column: span 3;
  }
  .GridItem:nth-child(3n) {
    grid-column: span 6;
  }
}

@media screen and (min-width: 1024px) {
  .GridItem {
    grid-column: span 2;
  }
  .GridItem:nth-child(5n),
  .GridItem:nth-child(5n-1) {
    grid-column: span 3;
  }
}
