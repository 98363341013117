$content-max-width: 1260px;

$breakpoint-hd: 1260px;
$breakpoint-desktop: 1024px;
$breakpoint-tablet: 768px;

$color--glassbla01: #88c9d0;
$color--glassbla02: #147e87;
$color--glassbla03: #005763;
$color--glassbla04: rgb(0, 87, 99); /* #005763 */
$color--jord: #9a7512;
$color--betong: #1d1d1b;
$color--white: #ffffff;
$color--background: #f7f8fa;

$color--signalgul: #ffe600;
$color--vern: #ff9900;
$color--rust: #cf4f13;
$color--interior: #9c296d;
$color--miljo: #5c9424;

$color--stein: #232f3a;
$color--input-border-gray: #e6e6e6;

// FONTS//

$font-family: "Open Sans", impact, charcoal, sans-serif;
$font-family-secondary: helvetica, "Oswald", arial, helvetica, sans-serif;
$font-gt-america-bold: "GT America Bold";
$font-gt-america: "GT America";
