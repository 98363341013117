.SvarDetaljSide {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 30px;
  row-gap: 20px;
  grid-template-areas:
    'heading heading'
    'cfMenu cfMenu'
    'images images'
    'rating rating'
    'Kontaktperson info'
    'utleier info'
    'files info'
    'standard info'
    'standard comment'
    'rec-tool rec-tool'
    'recind-box recind-box';
}

@media screen and (max-width: 768px) {
  .SvarDetaljSide {
    grid-template-columns: 1fr;
    grid-template-areas:
      'heading'
      'cfMenu'
      'images'
      'rating'
      'info'
      'info'
      'info'
      'info'
      'Kontaktperson'
      'utleier'
      'files'
      'standard'
      'comment'
      'rec-tool'
      'recind-box';
  }
}

.Images {
  grid-area: images;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: 300px;
  gap: 30px;
}

.Images a,
.Images button {
  box-shadow: 0 10px 20px 0 #48656f0d;
  background: white;
  position: relative;
  color: black;
  font-size: 20px;
  text-decoration: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: grid;
  align-items: stretch;
}

.Images img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.Heading {
  grid-area: heading;
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: center;
  grid-row-gap: 1em;
}

.TilleggsMatrikkel{
  grid-column: 1;
}
.recindLabelWrapper{
  display: flex;
  justify-content: flex-end;
}
.recindLabel{
  padding: 10px 15px 10px 15px;
  background-color: #ffa7a7;
  border-radius: 30px;
  font-weight: 700;
  font-size: 16px;
  margin-top: 17px;
  
}

.HeadingInfo {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: 1 / -1;
}

.HeadingInfo > * {
  margin-right: 50px;
}

.Rating {
  grid-area: rating;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-template-rows: auto;
  column-gap: 30px;
  justify-content: space-between;
  align-items: stretch;
}

.Rating > div {
  box-shadow: 0 10px 20px 0 #48656f0d;
  background: white;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.Rating svg {
  margin: 10px;
}

.Info {
  grid-area: info;
}

.BorderedContainer > div {
  border-bottom: none;
}
.BorderedContainer > div:last-child {
  border-bottom: 1px solid black;
}

.Info h2:nth-of-type(1) {
  margin-top: 0;
}

.Info h2{
  margin: 30px 0px;
  color: #232E3A;
}

div.Utleier {
  grid-area: utleier;
  margin-bottom: 0;
  /* border-top: none; */
}
div.Files {
  grid-area: files;
  margin-bottom: 0;
  /* border-top: none; */
}

div.Standard {
  grid-area: standard;
  margin-bottom: 0;
}

div.toolbar {
  grid-area: rec-tool;
  
  width: 100%;
  margin: 4em 0em;
}

div.embeddedButton button {
  margin-bottom: 16px;
}

div.kommentarWrapper {
  width: inherit;
}

div.ekstraKommentarInfo {
  display: grid;
  grid-template-columns: minmax(250px, 1fr) 2fr;
  align-items: flex-start;
  grid-gap: 30px;
}

div.Innsendt{
  grid-column: 1/2;
  grid-row: 1/3;
}

div.Innsendt > p {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 1em;
}

div.Innsendt > p > b {
  margin-top: 1em;
}

div.Vurdering{
  grid-column: 2/3;
  flex-wrap: wrap;
}

.Avstander {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.MapPopup {
  display: grid;
  width: 70vw;
  height: 50vh;
}

.expandButton {
  position: absolute;
  background: white;
  top: 0;
  right: 0;
  padding: 10px;
  z-index: 1000;
}

.tilBefaring {
  display: flex;
  align-items: center;
  grid-column: 2/3;
  margin-left: auto;
}
.tilBefaring input {
  margin-right: 8px;
}

.PrisEstimatListe{

}

.PrisEstimatListe > p:not(:nth-of-type(6))::after{
  content: "kr";
}

.PrisEstimatListe > p:nth-of-type(6)::after{
  content: "%";
}

@media screen and (max-width: 768px) {
  div.ekstraKommentarInfo {
    grid-template-columns: 1fr;
  }
  div.Vurdering{
    grid-column: 1/2;
  }
  div.Innsendt > p{
    align-items: center;
  }
  .tilBefaring{
    margin:auto;
    grid-column: 1/2;
  }
}

.kommentarInputWrapper {
  grid-area: comment;
  flex-direction: column;
}
.kommentarInputWrapper button {
  margin-top: 16px;
}

.recindBox{
  grid-area: recind-box;
}