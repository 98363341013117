.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
}

.wrapper.disabled {
  opacity: 0.5;
}

.labelTitle {
  display: block;
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 0.5em;
}

.leftSideContainer {
  width: 100%;
  margin-bottom: 1em;
}

.editField {
  position: relative;
}

.editButtonContainer {
  display: flex;
}
.anbefalingContainer {
  display: flex;
  flex-direction: column;
}

.radioContainer {
  margin: 0.5em;
  display: flex;
  align-items: center;
}

.radioContainer label {
  font-style: normal;
  margin-left: 1em;
}

.editButton {
  background: none;
  border: none;
  height: 2em;
}
.commentText {
  font-style: italic;
}

.inputContainer {
  width: 100%;
  resize: vertical;
}

.input {
  font-family: 'Open Sans', sans-serif;
  padding: 0.5em;
  width: 100%;
  min-height: 200px;
  resize: vertical;
}

.commentInput {
  font-family: 'Open Sans', sans-serif;
  padding: 0.5em;
  width: 60vh;
  min-height: 200px;
}

.tooltipContainer {
  position: absolute;
  right: 0;
  width: unset;
}

.emptyNote {
  background: white;
  padding: 0.5em;
  border: 1px solid #666;
  margin-top: 0;
  font-size: 14px;
  height: 150px;
}
.popupActionContainer {
  display: flex;
}
.popupActionContainer button {
  margin-right: 0.5em;
}

.helptextList {
}

.helptextItem {
}

.printInput {
  display: none;
}

@media screen and (min-width: 768px) {
  .wrapper {
    flex-direction: row;
  }
  .leftSideContainer {
    width: 55%;
    margin-bottom: 0;
  }
  .inputContainer {
    margin: 0 1em;
  }
  .tooltipContainer {
    width: 50px;
    position: relative;
  }
  .input {
    min-height: 200px;
  }
}

@media print {
  .editButton,
  .tooltipContainer,
  .input,
  .emptyAddComment {
    display: none;
  }
  .printInput {
    display: block;
  }
}
