.default {
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 50px 20px;
  margin-bottom: 30px;
}

.blue {
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
  background-color: var(--primary_04);
  padding: 50px 20px;
  margin-bottom: 30px;
}

.border {
  border-color: var(--color);
  border-width: 1px !important;
  border-top: solid;
  border-bottom: solid;
  padding: 20px 0;
}

.dashboardtable {
  max-width: 1000px;
}
