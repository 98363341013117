.projectNav,
.top {
  background: white;
}

.top {
  padding-top: 30px;
  padding-bottom: 30px;
}

.nav {
  background-color: var(--primary_03);
}

.projectNav,
.nav {
  height: 100%;
}

.nav > div {
  height: 100%;
}

.top > div,
.projectNav > nav,
.nav > nav {
  max-width: var(--content-max-width);
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top > div {
  max-width: calc(var(--content-max-width));
}

.nav > nav {
  height: 70px;
}

.headerFlexRow {
  display: flex;
  align-items: center;
}

.nav .desktopNav .goToLabel {
  color: white;
  padding: 26px 12px 26px 0;
}
.nav .desktopNav a {
  padding: 26px 12px;
}

.projectNav .desktopNav a {
  padding: 10px 12px;
}

.desktopNav a {
  color: white;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  height: 100%;
}

.nav a,
.nav .goToLabel {
  color: white;
}

.nav a.selected {
  text-shadow: 0.5px 0.5px 0px black;
  color: var(--secondary_05);
  background-color: white;
}

.nav a:hover {
  text-shadow: 0.5px 0.5px 0px black;
  background-color: rgba(255, 255, 255, 0.5);
  color: var(--secondary_05);
}

.projectNav a {
  color: var(--secondary_05);
  min-width: 80px;
}
.projectNav a:hover {
  /* box-shadow: 0 3px 0 0 var(--primary_01); */
}

.projectNav a.selected {
  font-weight: 700;
  box-shadow: 0 3px 0 0 var(--primary_01);
}

.projectNavContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectName {
  text-decoration: none;
  margin: 0.5em auto 0.5em 0em;
  transition: transform 100ms ease-in;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: inherit;
  padding: 15px;
  padding-bottom: 6px;
}

.projectName:hover {
  box-shadow: 0 1px 0 0 black !important;
}

.hamburgerButtonWrapper {
  margin-right: 40px;
  margin-top: -10px;
}

@media (max-width: 768px) {
  .hamburgerButtonWrapper {
    margin-right: 0px;
    margin-left: 40px;
    margin-top: -10px;
  }
}

.logo {
  font-family: 'Oswald', arial, helvetica, sans-serif;
  font-weight: normal;
  font-size: 20px;
  color: inherit;
  text-decoration: none;
  z-index: 110;
}

.logo img {
  height: 30px;
}

.logo:hover {
  color: #005763;
}

button.user {
  border: none;
  background: none;
  font-family: inherit;
  cursor: pointer;
  position: relative;
}

button.user .open {
  transform: rotate(180deg);
}

a.login {
  color: inherit;
  text-decoration: none;
}

.user,
.login {
  margin: 0;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.user span,
.login span {
  margin: 0 15px;
}

.dropdown {
  position: absolute;
  text-align: left;
  white-space: nowrap;
  top: 100%;
  right: 0;
  min-width: 100%;
  z-index: 100;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 25px;
  background: white;
}

.dropdown a {
  color: inherit;
  text-decoration: none;
}

.mobileNav {
  flex: 1 1 auto;
  width: 100%;
}

.icons {
  font-size: 40px;
  display: flex;
  align-items: center;
}

.icons button,
.icons a {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .notMobile {
    display: none;
  }

  .icons {
    display: flex;
  }

  .desktopNav {
    display: none;
  }
  .projectNav > nav {
    height: 100px;
  }
  .projectNavContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
}

@media (min-width: 768px) {
  .mobileNav {
    display: none;
  }

  .projectNav > nav,
  .top > div,
  .nav > nav {
    padding: 0 30px;
  }
}
