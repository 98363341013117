.general {
  margin-top: 0;
  color: black;
}

p.general {
  margin-bottom: 16px;
}

.center {
  text-align: center;
}

.noMargin,
p.general.noMargin {
  margin: 0;
}

.font-overskrift-mengdetekst {
  font-family: var(--gt-america);
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #000000;
}

.font-meny-hovedpunkt {
  font-family: var(--gt-america);
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
}

.font-menu-main {
  font-family: var(--gt-america-condensed-medium);
  font-size: 22px;
  letter-spacing: 0;
  line-height: 28px;
}

.font-menu-sub {
  font-family: var(--gt-america-mono);
  font-size: 15px;
  letter-spacing: -0.1em;
  line-height: 19px;
}

.font-tag {
  font-family: var(--gt-america-mono);
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 22px;
}

.font-tag-bold {
  font-family: var(--gt-america-mono);
  font-weight: 700;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 22px;
}

.font-article-h1 {
  font-family: var(--canela);
  font-size: 60px;
  letter-spacing: 0.05em;
  line-height: 78px;
}
.font-article-h2 {
  font-family: var(--canela);
  font-size: 50px;
  letter-spacing: 0.05em;
  line-height: 62px;
}
.font-article-h3 {
  font-family: var(--canela);
  font-size: 40px;
  letter-spacing: 0.05em;
  line-height: 55px;
}
.font-article-ingress-1-2 {
  font-family: var(--canela-text);
  font-size: 20px;
  letter-spacing: 0.05em;
  line-height: 30px;
}
.font-article-ingress-3-4 {
  font-family: var(--canela-text);
  font-size: 20px;
  letter-spacing: 0.05em;
  line-height: 28px;
}

.font-project-h1 {
  font-family: var(--gt-america-compressed);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 40px;
  letter-spacing: 0.05em;
  line-height: 43px;
}

.font-byline {
  font-family: var(--gt-america-mono);
  font-size: 13px;
  letter-spacing: 0.24em;
  line-height: 20px;
}

.font-byline-bold {
  font-family: var(--gt-america-mono);
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 0.24em;
  line-height: 20px;
}

.font-paragraph-header {
  font-family: var(--gt-america-condensed-medium);
  font-weight: 700;
  font-size: 25px;
  letter-spacing: 1px;
  line-height: 31px;
}

.font-paragraph {
  font-family: var(--gt-america);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 31px;
}

.font-list-title {
  font-family: var(--gt-america-compressed);
  font-weight: 700;
  font-size: 60px;
  letter-spacing: 0;
  line-height: 65px;
}

.font-list-item {
  font-family: var(--gt-america);
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 22px;
}

.font-list-item-bold {
  font-family: var(--gt-america);
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 22px;
}

.font-panel-heading {
  font-family: var(--gt-america);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.font-table-header {
  font-family: var(--gt-america-mono);
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 24px;
}
.font-alt-text {
  font-family: var(--gt-america-mono);
  font-size: 15px;
  letter-spacing: -3%;
  line-height: 20px;
}
.font-alt-text-bold {
  font-family: var(--gt-america-mono);
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -3%;
  line-height: 20px;
}

.font-download-link {
  font-family: var(--gt-america-mono);
  font-size: 16px;
  letter-spacing: -3%;
  line-height: 20px;
}

.font-button {
  font-family: var(--gt-america);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 22px;
}

.font-contact-title {
  font-family: var(--gt-america-mono);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0em;
  line-height: 24px;
}
.font-publication-title {
  font-family: var(--gt-america-condensed);
  font-weight: 700;
  font-size: 25px;
  letter-spacing: 0.2em;
  line-height: 31px;
}

.font-article-ingress {
  font-family: var(--canela-text);
  font-size: 26px;
  letter-spacing: 0em;
  line-height: 36px;
}
.font-article-quote {
  font-family: var(--canela-text);
  font-size: 50px;
  letter-spacing: 0em;
  line-height: 60px;
}

.font-article-author {
  font-family: var(--gt-america-mono);
  font-size: 15px;
  letter-spacing: 0.24em;
  line-height: 24px;
}
.font-news-title {
  font-family: var(--gt-america);
  font-weight: 700;
  font-size: 60px;
  letter-spacing: 5%;
  line-height: 75px;
}
/* @media (max-width: 768px) {
  .font-news-title {
    line-height: 55px;
    font-size: 40px;
  }
} */

.font-news-ingress {
  font-family: var(--gt-america);
  font-size: 26px;
  letter-spacing: 0;
  line-height: 36px;
}

.font-news-paragraph {
  font-family: var(--gt-america);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.05em;
  text-align: left;
}
.font-property-title {
  font-family: var(--gt-america-compressed);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 80px;
  letter-spacing: 0.05em;
  line-height: 100px;
}
.font-property-ingress {
  font-family: var(--canela-text);
  font-size: 26px;
  letter-spacing: 0;
  line-height: 36px;
}
.font-mono-16 {
  font-family: var(--gt-america-mono);
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 24px;
}

.font-annonse-grid-title {
  font-family: var(--gt-america);
  font-weight: 700;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
}
