.SvarTomteRedigerContainer {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-areas:
    'title title'
    'Kontaktperson Kontaktperson'
    'kopier kopier'
    'matrikkel matrikkel'
    'kontaktinfo kontaktinfo'
    'generell generell'
    'transport transport'
    'size size'
    'pris pris'
    'bilde bilde'
    'kommentar kommentar'
    'errorList errorList'
    'buttons buttons';

  gap: 15px;
  align-items: start;
}

@media (min-width: 768px) {
  .SvarTomteRedigerContainer {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas:
      'title title        title Kontaktperson'
      'kopier kopier       kopier kopier'
      'matrikkel matrikkel    matrikkel matrikkel'
      'kontaktinfo kontaktinfo  kontaktinfo kontaktinfo'
      'generell generell     generell generell'
      'transport transport    transport transport'
      'size size         pris pris'
      'bilde bilde        bilde bilde'
      'kommentar kommentar    kommentar kommentar'
      'errorList errorList    errorList errorList'
      'buttons buttons      buttons buttons';
  }
}

.threewayGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-areas: 'a a' 'b c';
  grid-gap: 15px;
  align-items: flex-end;
}
.threewayGrid > *:nth-child(1) {
  grid-area: a;
  grid-template-columns: 1fr;
  column-gap: 0;
}
.threewayGrid > *:nth-child(2) {
  grid-area: b;
}
.threewayGrid > *:nth-child(3) {
  grid-area: c;
}

@media (max-width: 768px) {
  .threewayGrid {
    grid-template-areas: 'a ' 'b' ' c';
  }
}

.SvarTomteRedigerContainer > div {
  margin-bottom: 0;
  height: 100%;
}

div.titles {
  grid-area: title;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titles h1 {
  margin: 0;
  color: #232e3a;
}

.titles h2 {
  margin: 0;
  color: #147e88;
}

.kopier {
  grid-area: kopier;
}

div.popupButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
}

div.popupButtons button:first-child {
  margin-right: 10px;
}

.kopier > div {
  display: flex;
}

.kopier > div > div {
  flex: 1 1 auto;
}

.kopier > div > button[data-busy] {
  cursor: wait;
}

.kontaktinfo {
  grid-area: kontaktinfo;
}

@media (min-width: 768px) {
  .kontaktinfo {
    grid-column: span 2;
  }
}

div.matrikkel {
  grid-area: matrikkel;
  margin-bottom: 0;
  grid-gap: 15px;
  display: flex;
  flex-direction: column;
}
div.matrikkel > div {
  margin-bottom: 0;
}

div.matrikkelpanel {
  position: relative;
}

div.matrikkelpanel > button {
  position: absolute;
  top: 10px;
  right: 10px;
}

div.addMatrikkel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.generell {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (min-width: 768px) {
  div.generell {
    grid-area: generell;
    margin-bottom: 0;
    display: grid;
    grid-auto-rows: min-content;
  }
}

div.size {
  grid-area: size;
  margin-bottom: 0;
  column-gap: 15px;
  display: grid;
  row-gap: 15px;
  grid-auto-rows: min-content;
}

div.transport {
  grid-area: transport;
  margin-bottom: 0;
  gap: 15px;
  grid-auto-rows: min-content;
}

div.areal {
  grid-area: areal;
  margin-bottom: 0;
  display: grid;
  gap: 15px;
  grid-auto-rows: min-content;
}

div.pris {
  grid-area: pris;
  margin-bottom: 0;
  display: grid;
  gap: 15px;
  grid-auto-rows: min-content;
}

div.bilde {
  grid-area: bilde;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (min-width: 768px) {
  div.bilde {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-auto-rows: min-content;
    grid-auto-flow: column;
  }
}

div.bilde h2 {
  grid-column: span 2;
}

div.kommentar {
  grid-area: kommentar;
  margin-bottom: 0;
  grid-auto-rows: min-content;
  display: grid;
  row-gap: 15px;
}

ul.errorList {
  grid-area: errorList;
  list-style: none;
  padding: 0;
}

ul.errorList li {
  position: relative;
  display: flex;
  flex-direction: column;
}

ul.errorList aside {
  margin-top: 50px;
  color: #636363;
}

ul.errorList button {
  background: none;
  border: none;
  text-decoration: underline;
  color: #0000ee;
  font-size: 16px;
  margin-left: 10px;
}

div.toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

div.buttons {
  grid-area: buttons;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 140px;
}

.buttons button {
  margin-right: 24px;
}

.fullSpan {
  grid-column: 1 / -1;
}

.buttonRow {
  display: flex;
}
