.BefaringInnkomneSide {
  display: grid;
  gap: 40px;
  margin-bottom: 5em;
}

.InnkomneList {
  display: grid;
  gap: 40px;
  margin-bottom: 5em;
}

.BefaringInnkomneSide h1 {
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
