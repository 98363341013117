.GrunnlagsdataRedigerSide {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    'generell generell generell generell'
    'filer filer filer filer'
    'transport transport transport transport'
    'pris pris pris pris'
    'annet annet annet annet';
  column-gap: 30px;
  row-gap: 0;
}

.GrunnlagsdataRedigerSide option,
.GrunnlagsdataRedigerSide input::placeholder,
.GrunnlagsdataRedigerSide input {
  font-family: var(--gt-america-mono);
}

.GrunnlagsdataRedigerSide h2 {
  grid-column: span 2;
}

.GenerellArea {
  grid-area: generell;
  display: grid;
  grid-column: span 4;
  grid-gap: 15px;
  row-gap: 15px;

  align-items: start;
}

.FilerArea {
  grid-area: filer;
  display: grid;
  grid-column: span 4;
  grid-gap: 15px;
}

.PrisArea {
  grid-area: pris;
  display: grid;
  grid-column: span 4;
  grid-gap: 15px;
}

.TransportArea {
  grid-area: transport;
  grid-column: span 4;
  display: flex;
  flex-direction: column;
}

.AnnetArea {
  grid-area: annet;
  grid-column: span 3;
  grid-gap: 15px;
  display: flex;
  flex-direction: column;
}

.AnnetArea > div {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.AnnetArea textarea {
  flex: 1 1 auto;
}

@media screen and (max-width: 768px) {
  .GrunnlagsdataRedigerSide {
    grid-template-columns: 1fr;
    grid-template-areas:
      'generell'
      'pris'
      'transport'
      'annet';
  }

  .GenerellArea {
    display: flex;
    flex-direction: column;
  }

  .GenerellArea > div {
    width: 100%;
  }

  .PrisArea,
  .TransportArea,
  .AnnetArea,
  .KollektivTransport {
    grid-column: span 4;
  }
}
