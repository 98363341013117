.hjelpetekster {
}

.hjelpetekster details {
  border-bottom: 1px solid #aaa;
  padding: 1em 0;
}

.hjelpetekster details summary {
  font-size: 1.25em;
  position: relative;
  cursor: pointer;
}

.missing {
  font-style: italic;
  color: #aaa;
}

.hjelpetekster details summary small {
  font-size: 0.5em;
  position: absolute;
  right: 0;
  bottom: 0;
}

button.icon {
  display: inline-flex;
  align-items: center;
  border: none;
  background: none;
  font-size: inherit;
  padding: 0;
  cursor: pointer;
}

button.icon svg {
  margin-right: 10px;
}

.hjelpetekster p {
  margin: 1em;
}

.footer {
  margin-top: 3em;
}

.edit {
  margin: 1em 1em 0 1em;
}

.noText {
  padding: 4em;
  color: #aaa;
  text-align: center;
}
