.energimerke {
  min-width: 200px;
}

.merke {
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  font-family: sans-serif;
  font-weight: bold;
  align-items: flex-end;
  color: white;
  text-shadow: 0 0 3px black;
  font-size: 20px;
  margin: 5px;
  cursor: pointer;
}

.menuList {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.option:hover {
  background: #ccc;
}
