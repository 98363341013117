.button {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 2px solid var(--button-disabled-darker);
  border-radius: 50%;
  font-size: 1.5em;
  color: #232f3a;
}

.finished {
  background-color: var(--primary_02);
  border: 2px solid var(--primary_02);
  width: 16px;
  height: 16px;
}

.active {
  background: #ffffff;
  border: 2px solid var(--primary_03);
  width: 32px;
  height: 32px;
}

.finished:focus {
  background-color: var(--primary_01);
  border: 2px solid var(--primary_01);
}

.active:focus {
  background: #ffffff;
  border: 2px solid var(--primary_02);
}

.buttonWrapper {
  min-height: 43px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.label {
  min-height: 20px;
  font-size: 0.87rem;
  text-align: center;
  position: absolute;
  top: 40px;
}
