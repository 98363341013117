
// begrenset til å gjelde bare størrelser < breakpoint-tablet 
@mixin breakpoint-below-tablet {
  @media screen and (max-width: $breakpoint-tablet - 1) {
    @content;
  }
}

// gjelder fra størrelse breakpoint-tablet og større (kaskade)
@mixin breakpoint-tablet {
  @media screen and (min-width: $breakpoint-tablet) {
    @content;
  }
}

// gjelder bare fra gitt breakpoint til neste (ingen kaskade)
@mixin breakpoint-tablet-only {
  @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop - 1) {
    @content;
  }
}

// gjelder fra størrelse breakpoint-tablet og større (kaskade)
@mixin breakpoint-desktop {
  @media screen and (min-width: $breakpoint-desktop) {
    @content;
  }
}

// gjelder bare fra gitt breakpoint til neste (ingen kaskade)
@mixin breakpoint-desktop-only {
  @media screen and (min-width: $breakpoint-desktop) and (max-width: $breakpoint-hd - 1) {
    @content;
  }
}

@mixin breakpoint-hd {
  @media screen and (min-width: $breakpoint-hd) {
    @content;
  }
}

@mixin button-style-reset {
  appearance: none;
  border: none;
  font-family: inherit;
  padding: 0;
}

@mixin event-log-grid-style {
  display: grid;
  grid-template-columns:  2fr 5fr 2fr 2fr 4em;
  padding: 15px 20px;
  >p{
    margin: 0px;
  }
  
  
}