.select {
  margin: 0;
  min-width: 200px;
  height: var(--button-height);
  font-family: var(--gt-america-mono);
  border: 2px solid var(--color--input-border-gray);
  border: solid 1px var(--field-color);
}

.select::placeholder {
  font-family: var(--gt-america-mono);
}

.selectGroup {
}

.select:global(.Select-menu-outer) {
  z-index: 1200;
}

.wide {
  min-width: 300px;
}

:global(.is-focused).select {
  border: solid 2px var(--primary-color);
}

.select > :global(.rw-input),
.select > :global(.rw-select) {
  height: 100%;
  display: flex;
  align-items: center;
  font-family: var(--gt-america-mono);
}

.select :global(.rw-list-option) {
  height: var(--button-height);
  font-family: var(--gt-america-mono);
}

:global(.form-group > .is-focused) {
  transition: all 0.05s ease 0s;
  border: 3px solid #945e88;
  background-color: #ffffff;
  box-shadow: none;
  outline: none;
}

.select :global(.Select-control) {
  background-color: inherit;
  border: 0px;
  color: #232f3a;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 100%;
}

.select :global(.Select-placeholder),
:global(.Select-value),
:global(.Select--single > .Select-control .Select-value) {
  line-height: var(--button-height);
  padding-right: 45px;
}
