.label {
  padding-left: 21px; /* same as <Panel> */
  font-size: 20px;
  font-weight: 400;
  grid-row-start: 1;
  grid-row-end: -1;
}

.Files svg {
  font-size: 35px;
}

.FileList a {
  color: var(--linkColor);
}
