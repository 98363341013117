.AnnonseBlock {
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
}

.AnnonseText {
  transition: color 100ms;
}

.AnnonseBlock:hover .AnnonseText {
  color: var(--primary_03) !important;
}

.headerOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--primary_03) !important;
  opacity: 0;
  transition: opacity 100ms;
}

.AnnonseBlock:hover .headerOverlay {
  opacity: 0.2;
}

.header {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  position: relative;
  height: 200px;
  background-position: center;
  margin-bottom: 20px;
}

.header img {
  max-width: 80%;
  object-fit: contain;
}

.footer {
  display: flex;
}
