.VurderingsPopup {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 30px;
  width: min(90vw, 800px);
}

.InfoPopup {
  width: min(90vw, 800px);
}

.Text {
  line-height: 1.5em;
  display: flex;
  flex-direction: column;
}

.Text h3 {
  margin-top: 0;
}

.Text strong {
  color: #147e87;
  font-weight: 600;
}

.Spacer {
  flex: 1 1 auto;
}

.Vurdering {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.Vurdering .ButtonContainer {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.Vurdering .ButtonContainer button {
  margin-right: 1em;
}

.Vurdering .Reset {
  color: white;
}

@media screen and (max-width: 768px) {
  .Vurdering {
    flex-direction: column;
  }

  .Vurdering .ButtonContainer {
    flex-direction: column;
  }
  .Vurdering .ButtonContainer button {
    margin-right: 0;
    margin-bottom: 1em;
    width: 100%;
  }
}

.Vurdering button.Bad {
  color: white;
  border: none;
  background: #ff9f92;
}
.Vurdering button.Medium {
  color: black;
  border: none;
  background: #ffeb88;
}
.Vurdering button.Good {
  color: white;
  border: none;
  background: #a4d67c;
}
.Avstander {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
