@import '../../scss/vars.scss';

$grid-setting: 2fr 6fr 2fr 2fr 2fr 1fr;

.dynamic-file-list {
  color: $color--betong;

  button {
    cursor: pointer;
    grid-column: 6/7;
    padding: 0;
    border: 0;
    background: none;
    color: $color--glassbla03;
  }

  &.-bottom-margin {
    margin-bottom: 3em;
  }

  &__filters {
    border-bottom: 1px solid $color--input-border-gray;
    padding-bottom: 10px;
    display: grid;
    grid-template-columns: $grid-setting;

    div {
      button {
        font-size: 0.9375rem;
        line-height: 1.25rem;
        display: flex;
        align-items: center;
        white-space: nowrap;

        svg {
          margin-left: 2px;
          color: $color--glassbla01;
        }
      }

      &:first-child {
        flex-shrink: 0;
        width: 220px;
        padding-right: 32px;
      }

      &:nth-child(2) {
        width: 100%;
      }
    }
  }

  ul {
    padding: 0;
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;

    li {
      height: 64px;
      border-bottom: 1px solid $color--input-border-gray;
      display: grid;
      grid-template-columns: $grid-setting;
      align-items: center;
      width: 100%;
    }
  }

  &__date {
    flex-shrink: 0;
    width: 220px;
    padding-right: 32px;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #5f5f5f;
  }

  &__displayName {
    display: flex;
    justify-content: space-between;
    width: 100%;

    a {
      font-size: 1.125rem;
      line-height: 1.25rem;
      color: $color--betong;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
