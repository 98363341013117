.defaults {
  transition: all 0.1s ease 0s;
  display: inline-block;
  box-sizing: border-box;
}

.defaultSize {
  fill: inherit;
  stroke: inherit;
  display: inline-flex;
  align-items: center;
  vertical-align: bottom;
}

button.defaultSize {
  background: transparent;
  border-radius: 50%;
  border: 2px solid inherit;
}

.sizex1 > svg {
  width: 1.1em;
  height: 1.1em;
}

.sizex2 > svg {
  width: 1.3em;
  height: 1.3em;
}

.sizex3 > svg {
  width: 1.6em;
  height: 1.6em;
}

.sizex4 > svg {
  width: 1.8em;
  height: 1.8em;
}

.sizex5 > svg {
  width: 2em;
  height: 2em;
  margin-top: -0.2em;
  margin-bottom: -0.3em;
  margin-left: 0.1em;
  margin-right: 0.6em;
}

.noMargin > svg {
  margin: 0;
}
