.wrapper {
  height: 53px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.boxWrapper {
  margin: 0 20px;
  height: 170px;
}

.boxLabel {
  padding: 15px 0 25px 40px;
}

.boxWrapper > .line {
  margin: 0 -10px;
  width: inherit;
}

.line {
  height: 3px;
  width: 100%;
  background-color: #d8d8d8;
  position: relative;
  display: flex;
}

.activeLine {
  background-color: var(--primary_02);
  height: 100%;
}

.inactiveLine {
  background-color: #d8d8d8;
}

.buttonContainer {
  margin-top: -20px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

/* .activeDot {
  border-radius: 50%;
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  border: 3px solid #147e87;
  background-color: #ffffff;
}

.finishedDot {
  height: 10px;
  width: 10px;
  background-color: #085762;
  border-radius: 50%;
} */

/* .notStartedDot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #e3e3e3;
} */

.buttonWrapper {
  width: 100%;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  width: 80px;
}
